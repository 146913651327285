@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .lang {
        @apply font-bold pl-2
    }

    .frm-input {
        @apply border p-2 rounded-md placeholder:font-rubik placeholder:text-sm bg-transparent placeholder:text-slate-400 focus:outline-none focus:border-violet-800 focus:ring-1 focus:ring-pink-600
    }

    .btn{
        @apply cursor-pointer bg-gradient-to-r from-violet-800 to-pink-500 px-16 py-6 rounded-md
    }

    .text-gradient{
        @apply bg-gradient-to-r from-violet-500 from-10% to-pink-500 to-90% text-transparent bg-clip-text
    }

    .img-box{
        /* -webkit-box-reflect: below 0px linear-gradient(to bottom, rgba(0,0,0,0.0), rgba(0,0,0,0.4)); */
        @apply w-[70px] h-[70px] lg:w-[100px] lg:h-[100px]
    }

}

@layer utilities{
    .no-scrollbar::-webkit-scrollbar{
        display: none;
    }
    .no-scrollbar{
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
}

@layer base{
    html{
        scroll-behavior: smooth;
        overflow-x: hidden;
    }
}